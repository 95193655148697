import axios from 'axios';
import config from '../config';
import {createAuth} from "../Helpers";


export const reducerName = 'frontpageStore';

const FRONTPAGE_SET_ACTIVE = 'FRONTPAGE_SET_ACTIVE';

const initialState = {
    activeFrontpage: 0,
};

export const actionCreators = {
    
    setActiveFrontpage: (frontpage) => async(dispatch, getState) => {
        dispatch({type: FRONTPAGE_SET_ACTIVE, frontpage});
    }
};

const reducerMethods = {
    FRONTPAGE_SET_ACTIVE: (state, action) => {
        return {
            ...state,
            activeFrontpage: action.frontpage != 0 ? state.activeFrontpage + action.frontpage : 0,
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};